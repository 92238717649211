var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-tab", {
        attrs: {
          type: "horizon",
          tabItems: _vm.tabItems,
          inlineLabel: true,
          dense: true,
          height: _vm.tabHeight,
        },
        on: {
          "update:tabItems": function ($event) {
            _vm.tabItems = $event
          },
          "update:tab-items": function ($event) {
            _vm.tabItems = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  tag: "component",
                  attrs: {
                    popupParam: _vm.popupParam,
                    construction: _vm.construction,
                    attachSafetyManageInfo: _vm.attachSafetyManageInfo,
                    attachRiskAssessInfo: _vm.attachRiskAssessInfo,
                    attachWorkPlanInfo: _vm.attachWorkPlanInfo,
                    attachEduCertiInfo: _vm.attachEduCertiInfo,
                    attachManageCostInfo: _vm.attachManageCostInfo,
                    attachInsuranceCertiInfo: _vm.attachInsuranceCertiInfo,
                    attachBusinessRegistrationInfo:
                      _vm.attachBusinessRegistrationInfo,
                    attachCredentialsInfo: _vm.attachCredentialsInfo,
                    attachEtcInfo: _vm.attachEtcInfo,
                    isOld: _vm.isOld,
                    contentHeight: _vm.contentHeight,
                  },
                  on: {
                    "update:construction": function ($event) {
                      _vm.construction = $event
                    },
                    "update:attachSafetyManageInfo": function ($event) {
                      _vm.attachSafetyManageInfo = $event
                    },
                    "update:attach-safety-manage-info": function ($event) {
                      _vm.attachSafetyManageInfo = $event
                    },
                    "update:attachRiskAssessInfo": function ($event) {
                      _vm.attachRiskAssessInfo = $event
                    },
                    "update:attach-risk-assess-info": function ($event) {
                      _vm.attachRiskAssessInfo = $event
                    },
                    "update:attachWorkPlanInfo": function ($event) {
                      _vm.attachWorkPlanInfo = $event
                    },
                    "update:attach-work-plan-info": function ($event) {
                      _vm.attachWorkPlanInfo = $event
                    },
                    "update:attachEduCertiInfo": function ($event) {
                      _vm.attachEduCertiInfo = $event
                    },
                    "update:attach-edu-certi-info": function ($event) {
                      _vm.attachEduCertiInfo = $event
                    },
                    "update:attachManageCostInfo": function ($event) {
                      _vm.attachManageCostInfo = $event
                    },
                    "update:attach-manage-cost-info": function ($event) {
                      _vm.attachManageCostInfo = $event
                    },
                    "update:attachInsuranceCertiInfo": function ($event) {
                      _vm.attachInsuranceCertiInfo = $event
                    },
                    "update:attach-insurance-certi-info": function ($event) {
                      _vm.attachInsuranceCertiInfo = $event
                    },
                    "update:attachBusinessRegistrationInfo": function ($event) {
                      _vm.attachBusinessRegistrationInfo = $event
                    },
                    "update:attach-business-registration-info": function (
                      $event
                    ) {
                      _vm.attachBusinessRegistrationInfo = $event
                    },
                    "update:attachCredentialsInfo": function ($event) {
                      _vm.attachCredentialsInfo = $event
                    },
                    "update:attach-credentials-info": function ($event) {
                      _vm.attachCredentialsInfo = $event
                    },
                    "update:attachEtcInfo": function ($event) {
                      _vm.attachEtcInfo = $event
                    },
                    "update:attach-etc-info": function ($event) {
                      _vm.attachEtcInfo = $event
                    },
                    "update:isOld": function ($event) {
                      _vm.isOld = $event
                    },
                    "update:is-old": function ($event) {
                      _vm.isOld = $event
                    },
                    "update:contentHeight": function ($event) {
                      _vm.contentHeight = $event
                    },
                    "update:content-height": function ($event) {
                      _vm.contentHeight = $event
                    },
                    getDetail: _vm.getDetail,
                    closePopup: _vm.closePopup,
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }